'use client'

import { useEffect } from 'react'

import {
  SEARCH_INTERACTED_KEY,
  useSetSessionStorageValuesForOptimizelyTracking,
} from './useSetSessionStorageValuesForOptimizelyTracking'

export const useSetSearchSessionStorageValuesForOptimizelyTracking =
  (): void => {
    const setSessionStorageValue =
      useSetSessionStorageValuesForOptimizelyTracking(SEARCH_INTERACTED_KEY)

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search)

      if (
        urlParams.has('queryID') ||
        urlParams.has('q') ||
        urlParams.has('query')
      ) {
        setSessionStorageValue()
      }
    })
  }
