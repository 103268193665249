import {
  type CrossSellProduct,
  type Metadata,
} from '@redteclab/api/clients/retail-media-xsell-service'

export const crossSellAccessorGetSponsoredProductLink = ({
  metadata,
  product,
}: {
  metadata?: Metadata
  product?: CrossSellProduct
}): string | undefined => {
  const metadataContent = product?.id
    ? metadata?.[product.id as keyof Metadata]
    : undefined

  /*
   * retail media click url is used instead of original deeplink
   * this property is defined only if the product is re-rank or injected from retail media service
   * it allows to track the clicks on the product
   */
  return metadataContent?.trackingUrls.clickUrl
}
