import { type IntlShape } from '@formatjs/intl'
import { type IntlShape as ReactIntlShape } from 'react-intl'

import { apmCaptureError } from '../apm'
import { priceGetFormattedPrice } from '../price'

const pricePattern =
  /^\*?(?<currency>.+)\s(?<amount>\d+[,.]\d+)(?<superscript>\p{No})?(?:\s\/\s(?<unit>.+))?$/u

interface PriceMatch {
  amount: string
  currency?: string
  superscript?: string
  unit?: string
}

const getMatchedGroups = (input: string): PriceMatch | null => {
  const match = pricePattern.exec(input.trim())
  if (!match?.groups) {
    return null
  }

  const { amount, currency, superscript, unit } = match.groups

  if (!amount) {
    return null
  }

  return { amount, currency, superscript, unit }
}

const addSuperscriptsIfNeeded = (
  matchedGroups: PriceMatch,
  formattedPrice: string,
  options: {
    isItalianStrikethrough?: boolean
  },
): string => {
  const { superscript } = matchedGroups

  /**
   * If superscript is present in response value, add it to the formatted price
   */
  if (superscript) {
    return `${formattedPrice}${superscript}`
  }
  /**
   * We have an usecase where we superscipt is not in response, but we need to add it.
   * Example is IT tenant where we need to add `¹` superscript to strikethrough price
   * https://jira.shop-apotheke.com/browse/WSAWA-6186
   *
   * This shall be on response and needs to be eventually cleaned up https://jira.shop-apotheke.com/browse/WSAWA-6212
   */
  if (!superscript && options.isItalianStrikethrough) {
    return `${formattedPrice}¹`
  }

  return formattedPrice
}

/**
 * The function reformats price coming from ECONDA
 *
 * Cross-sell price format:
 * ```
 * ┌─ optional old price asterisk
 * │┌─ currency sign (€) or code (CHF)
 * ││ ┌─ integer
 * ││ │ ┌─ decimal (dot or comma)
 * ││ │ │ ┌─ fraction
 * ││ │ │ │┌─ optional superscript
 * ││ │ │ ││   ┌─ optional unit
 * *€ 10,36¹ / st
 * ```
 */
export const crossSellGetFormattedPrice = (
  value: string,
  intl: IntlShape | ReactIntlShape,
  options: {
    isItalianStrikethrough?: boolean
  } = {},
): string | undefined => {
  /**
   * If the field value contains `NA`, it breaks the price pattern.
   * Examples: `€ NA / NA` & `€ NA / NA NA`.
   * This issue should be resolved by https://jira.shop-apotheke.com/browse/WSAWA-5767.
   */
  if (value.includes('NA')) {
    return undefined
  }

  /**
   * Trims the value. In the Belgium tenant, the price may have a leading space, e.g., ` € 7.49`.
   * See: https://jira.shop-apotheke.com/browse/WSAWA-5922
   */
  const matchedGroups = getMatchedGroups(value)

  if (!matchedGroups) {
    apmCaptureError(new Error(`Unable to parse cross-sell price: ${value}`))

    return value
  }

  const currencyCode =
    matchedGroups.currency === '€' ? 'EUR' : matchedGroups.currency

  // The price comes already formatted, to be able to parse it replace comma with dot
  const amount = Number.parseFloat(matchedGroups.amount.replace(',', '.'))

  let formattedPrice = priceGetFormattedPrice(amount, intl, {
    currency: currencyCode,
  })

  formattedPrice = addSuperscriptsIfNeeded(
    matchedGroups,
    formattedPrice,
    options,
  )

  if (matchedGroups.unit) {
    // Provide correct locale for `st` unit coming from ECONDA
    const unit =
      matchedGroups.unit === 'st'
        ? intl.formatMessage({ id: 'product.attribute.unit.pcs' })
        : matchedGroups.unit

    formattedPrice += ` / ${unit}`
  }

  return formattedPrice
}
