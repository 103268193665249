import NextLink from 'next/link'
import { type FC } from 'react'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Link, Skeleton } from 'base-ui'

import {
  CSW_INTERACTED_KEY,
  useSetSessionStorageValuesForOptimizelyTracking,
} from '../../../experiments/hooks'
import { useGlobalConfigContext } from '../../../global-config'
import { urlGetRelativeUrl } from '../../../url-handling'
import { crossSellAccessorGetLocalizedProductDeepLink } from '../../model/crossSellAccessorGetLocalizedProductDeepLink'
import { crossSellAccessorGetLocalizedProductName } from '../../model/crossSellAccessorGetLocalizedProductName'
import { crossSellAccessorGetSponsoredProductLink } from '../../model/crossSellAccessorGetSponsoredProductLink'
import { useCrossSellMetadataContext } from '../CrossSellMetatadaProvider'

type CrossSellProductNameProps = {
  product?: CrossSellProduct
}

export const CrossSellProductName: FC<CrossSellProductNameProps> = ({
  product,
}) => {
  const { language, tenant } = useGlobalConfigContext()
  const trackClick =
    useSetSessionStorageValuesForOptimizelyTracking(CSW_INTERACTED_KEY)
  const metadata = useCrossSellMetadataContext()

  if (!product) {
    return (
      <div className="flex flex-col items-center gap-2">
        <Skeleton className="w-full" />
        <Skeleton className="w-full" />
        <Skeleton className="w-3/4" />
      </div>
    )
  }

  const sponsoredProductLink = crossSellAccessorGetSponsoredProductLink({
    metadata,
    product,
  })

  return (
    <Link<typeof NextLink>
      className="line-clamp-3 overflow-hidden text-ellipsis font-medium text-dark-primary-max no-underline"
      data-analytics="item-title"
      data-qa-id={`form-product-slider-${product.id}-link-button`}
      href={
        sponsoredProductLink ??
        urlGetRelativeUrl(
          crossSellAccessorGetLocalizedProductDeepLink({
            language,
            product,
            tenant,
          }),
        )
      }
      onClick={trackClick}
      rel={sponsoredProductLink ? 'nofollow noopener' : undefined}
      slots={{ root: NextLink }}
      title={crossSellAccessorGetLocalizedProductName({
        language,
        product,
        tenant,
      })}
    >
      {crossSellAccessorGetLocalizedProductName({ language, product, tenant })}
    </Link>
  )
}
