import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Skeleton } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { TENANT } from '../../../tenant'
import { crossSellGetFormattedPrice } from '../../crossSellGetFormattedPrice'

type CrossSellProductPriceProps = {
  product?: CrossSellProduct
}

export const CrossSellProductPrice: FC<CrossSellProductPriceProps> = ({
  product,
}) => {
  const { tenant } = useGlobalConfigContext()
  const intl = useIntl()

  if (!product) {
    return (
      <div className="min-h-[39px]">
        <Skeleton className="mx-auto w-4/5" />
      </div>
    )
  }

  return (
    <div className="flex min-h-[39px] flex-col">
      <span className="flex flex-col">
        <span className="min-h-[21px] whitespace-nowrap text-dark-primary-low line-through">
          {product.reduced === 'true' &&
            crossSellGetFormattedPrice(product.oldprice, intl, {
              // on IT superscript is not in response, we need to force adding it for oldprice
              isItalianStrikethrough: tenant === TENANT.IT,
            })}
        </span>
        <span className="whitespace-nowrap text-2xl font-bold leading-l text-dark-brand">
          {crossSellGetFormattedPrice(product.price, intl)}
        </span>
      </span>
      <span className="min-h-[21px] text-s text-dark-primary-medium">
        {crossSellGetFormattedPrice(product.new_baseprice, intl)}
      </span>
    </div>
  )
}
