'use client'

import { type AxiosError } from 'axios'
import { getCookie } from 'cookies-next/client'
import { useCallback } from 'react'
import { type SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { type CrossSellResponse } from '@redteclab/api/clients/retail-media-xsell-service'

import { adServerCookiesGetOrGenerateUserIdentity } from '../../../ad-server'
import { buildHomeOneApiAxiosInstance } from '../../../base-api-communication'
import {
  COOKIE_AD_SERVER_USER_ID_NAME,
  COOKIE_ECONDA_VISITOR_ID_NAME,
} from '../../../cookies'
import { useExpaContext } from '../../../expa'
import { useExperimentsContext } from '../../../experiments/components/ExperimentsContext'
import {
  experimentAccessorIsAdServerEnabled,
  experimentAccessorIsAdServerUseGlooApiEnabled,
} from '../../../experiments/model/experimentAccessor'
import { useGlobalConfigContext } from '../../../global-config'
import { isServerSide } from '../../../request-context'
import { urlResolverGetCrossSellHorizontalSliderApiEndpoint } from '../../../url-handling'

export type CrossSellHorizontalSliderRequestParams = {
  categoryId?: string
  categoryLabel?: string
  cmUid?: string
  emcid?: string
  listPrice?: string
  productId?: string
  retailPrice?: string
  sellerId?: string
  visibleItemCount?: number
  widgetId: number | string
}

const extractEmvidFromVisitorIdCookie = (
  visitorIdCookie: string | undefined,
): string | null => {
  if (!visitorIdCookie) {
    return null
  }

  const parts = visitorIdCookie.split(':')

  return parts.at(0) ?? null
}

const CROSS_SELL_PDP_CENTER_SLIDER_COM_DE_ID = 297
const CROSS_SELL_HOMEPAGE_TOP_COM_DE_ID = 294
const CROSS_SELL_HOMEPAGE_BOTTOM_COM_DE_ID = 299
const CROSS_SELL_HOMEPAGE_BOTTOM_IT_IT_ID = 544
const CROSS_SELL_HOMEPAGE_BOTTOM_CH_FR_DE = 430
const CROSS_SELL_HOMEPAGE_BOTTOM_FR_FR = 97
const CROSS_SELL_HOMEPAGE_BOTTOM_AT_DE = 376
const CROSS_SELL_HOMEPAGE_BOTTOM_BE_FR_NL = 511
const CROSS_SELL_HOMEPAGE_BE_FR_NL = 489
const CROSS_SELL_CATEGORY_BE_FR_NL = 429

/*
 * For this specific widget ID, data needs to be fetched from the Retail Media API.
 * See ticket RMADT-2349 for more details.
 */
const crossSellWidgetsWithAds = new Set([
  CROSS_SELL_CATEGORY_BE_FR_NL,
  CROSS_SELL_HOMEPAGE_BE_FR_NL,
  CROSS_SELL_HOMEPAGE_BOTTOM_AT_DE,
  CROSS_SELL_HOMEPAGE_BOTTOM_BE_FR_NL,
  CROSS_SELL_HOMEPAGE_BOTTOM_CH_FR_DE,
  CROSS_SELL_HOMEPAGE_BOTTOM_COM_DE_ID,
  CROSS_SELL_HOMEPAGE_BOTTOM_FR_FR,
  CROSS_SELL_HOMEPAGE_BOTTOM_IT_IT_ID,
  CROSS_SELL_HOMEPAGE_TOP_COM_DE_ID,
  CROSS_SELL_PDP_CENTER_SLIDER_COM_DE_ID,
])

export const useSwrCrossSellHorizontalSlider = (
  params?: CrossSellHorizontalSliderRequestParams,
): SWRResponse<CrossSellResponse, AxiosError> => {
  const publicGlobalConfig = useGlobalConfigContext()
  const { language, locale, shopEnv, tenant, tenantAndEnv } = publicGlobalConfig
  const { experiments } = useExperimentsContext()
  const isAdServerEnabled = experimentAccessorIsAdServerEnabled(experiments)
  const isAdServerGlooApiEnabled =
    experimentAccessorIsAdServerUseGlooApiEnabled(experiments)

  const expa = useExpaContext() ?? 'direct'

  let crossSellParams: CrossSellHorizontalSliderRequestParams | null = null

  if (!isServerSide()) {
    crossSellParams = {
      cmUid: params?.cmUid,
      // @ts-expect-error -- need update
      coreShopEnvironment: shopEnv,
      csc: expa,
      csize: 50,
      'ctxcat.cid': params?.categoryId,
      'ctxcat.ct': params?.categoryId && 'productcategory',
      'ctxcustom.content': expa,
      'ctxcustom.sellerId': params?.sellerId,
      emcid: params?.emcid,
      expa,
      language,
      listPrice: params?.listPrice,
      locale,
      pid: params?.productId,
      retailPrice: params?.retailPrice,
      start: 0,
      tenant,
      tenantPath: tenantAndEnv,
      type: 'cs',
      visibleItemCount: params?.visibleItemCount,
      wid: params?.widgetId,
      widgetdetails: true,
    }
  }

  const fetcher = useCallback(
    async ([url, wid, fetchParams]: [
      url: string,
      wid: CrossSellHorizontalSliderRequestParams['widgetId'],
      fetchParams: CrossSellHorizontalSliderRequestParams,
    ]): Promise<CrossSellResponse> => {
      const emvid = extractEmvidFromVisitorIdCookie(
        getCookie(COOKIE_ECONDA_VISITOR_ID_NAME),
      )

      if (isAdServerEnabled && crossSellWidgetsWithAds.has(Number(wid))) {
        const { browserIdConversion, browserIdTargeting } =
          adServerCookiesGetOrGenerateUserIdentity()
        const body = {
          adServerTargeting: window._targeting,
          isAdServerGlooApiEnabled,
          user: {
            asbidc: browserIdConversion,
            asbidt: browserIdTargeting,
            asuid: getCookie(COOKIE_AD_SERVER_USER_ID_NAME),
          },
        }

        return buildHomeOneApiAxiosInstance(publicGlobalConfig)
          .post<CrossSellResponse>(url, body, {
            params: { ...fetchParams, emvid },
          })
          .then(({ data }) => data)
      }

      return buildHomeOneApiAxiosInstance(publicGlobalConfig)
        .get<CrossSellResponse>(url, { params: { ...fetchParams, emvid } })
        .then(({ data }) => data)
    },
    [publicGlobalConfig, isAdServerEnabled, isAdServerGlooApiEnabled],
  )

  return useSWRImmutable(() => {
    if (!crossSellParams || !params) {
      return null
    }

    const { widgetId } = params

    return [
      urlResolverGetCrossSellHorizontalSliderApiEndpoint(),
      widgetId,
      crossSellParams,
    ]
  }, fetcher)
}
